/*
 * Side Bar - 프로모션 관리
 */

import { Part } from 'config';
import { IconGift } from '@tabler/icons';

// ==============================|| PROMOTION MENU ITEMS ||============================== //

const product = {
  id: 'promotion',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin],
  children: [
    {
      id: 'promotionTab',
      title: '프로모션 관리',
      type: 'collapse',
      children: [
        // 셀프 프로모션 관리
        {
          id: 'promotionManage',
          title: '셀프 프로모션 관리',
          type: 'item',
          url: '/self-promotion',
          icon: IconGift,
          breadcrumbs: true
        }
        // 오늘의 한 병 관리
        // {
        //   id: 'todayOneBottleManage',
        //   title: '오늘의 한 병 관리',
        //   type: 'item',
        //   url: '/product/today-one-bottle',
        //   icon: IconConfetti,
        //   breadcrumbs: true
        // },
        // 공동 구매 관리
        // {
        //   id: 'coBuyingManage',
        //   title: '공동 구매 관리',
        //   type: 'item',
        //   url: '/promotion/co-buying',
        //   icon: IconBoxSeam,
        //   breadcrumbs: false
        // }
      ]
    }
  ]
};

export default product;
