/*
 * Side Bar - 정책/약관 관리
 */
import { Part } from 'config';
import { IconCheckupList, IconCreditCard, IconFileCheck, IconFileDescription, IconMap2, IconUserCheck } from '@tabler/icons';

// ==============================|| PRODUCT MENU ITEMS ||============================== //

const policy = {
  id: 'policy',
  title: '',
  type: 'group',
  part: [Part.Admin, Part.SubAdmin],
  children: [
    {
      id: 'policyTab',
      title: '정책/약관 관리',
      type: 'collapse',
      children: [
        {
          id: 'policyServiceManage',
          title: '서비스 이용약관',
          type: 'item',
          url: '/policy/service',
          icon: IconCheckupList,
          breadcrumbs: true
        },
        {
          id: 'policyPersonalInformation',
          title: '개인정보 처리방침',
          type: 'item',
          url: '/policy/personal_information',
          icon: IconUserCheck,
          breadcrumbs: true
        },
        {
          id: 'policyTransactionManage',
          title: '전자금융거래 이용약관',
          type: 'item',
          url: '/policy/transaction',
          icon: IconCreditCard,
          breadcrumbs: true
        },
        {
          id: 'policyLocationInformationManage',
          title: '위치정보 이용약관',
          type: 'item',
          url: '/policy/location_information',
          icon: IconMap2,
          breadcrumbs: true
        },
        {
          id: 'policyPersonalAgreeManage',
          title: '일반회원 개인정보 동의서',
          type: 'item',
          url: '/policy/personal_agree',
          icon: IconMap2,
          breadcrumbs: true
        }
        // {
        //   id: 'partnersPersonalInformationManage',
        //   title: '파트너 개인정보 처리방침',
        //   type: 'item',
        //   url: '/policy/partners-agree',
        //   icon: IconFileDescription,
        //   breadcrumbs: true
        // },
        // {
        //   id: 'partnersUniqueIdentificationManage',
        //   title: '파트너 식별정보 처리방침',
        //   type: 'item',
        //   url: '/policy/partners-unique-agree',
        //   icon: IconFileCheck,
        //   breadcrumbs: true
        // }
      ]
    }
  ]
};

export default policy;
