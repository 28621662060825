import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
import DashboardDefault from 'views/dashboard/Default';
import { PartGuard } from '../utils/route-guard/v2';
import { Part } from '../config';

// 정산관리
const Calculate = Loadable(lazy(() => import('views/calculate/index')));
const CalculateVendor = Loadable(lazy(() => import('views/calculate/vendor/index')));
const CalculateHistory = Loadable(lazy(() => import('views/calculate/history')));
const CalculateHistoryDetail = Loadable(lazy(() => import('views/calculate/history/detail')));

// 이벤트/혜택관리
const Banner = Loadable(lazy(() => import('views/benefit/banner/BannerManage')));
const BannerCreate = Loadable(lazy(() => import('views/benefit/banner/BannerCreate')));
const BannerUpdate = Loadable(lazy(() => import('views/benefit/banner/BannerUpdate')));
const Push = Loadable(lazy(() => import('views/benefit/push/PushList')));
const PushCreate = Loadable(lazy(() => import('views/benefit/push/CreatePush')));
const MainPopupList = Loadable(lazy(() => import('views/benefit/mainPopup/MainPopupList')));
const MainPopupCreate = Loadable(lazy(() => import('views/benefit/mainPopup/MainPopupCreate')));
const MainPopupUpdate = Loadable(lazy(() => import('views/benefit/mainPopup/MainPopupUpdate')));

const Coupon = Loadable(lazy(() => import('views/benefit/coupon/index')));
const CouponCreate = Loadable(lazy(() => import('views/benefit/coupon/CouponCreate')));
const CouponDetail = Loadable(lazy(() => import('views/benefit/coupon/CouponDetail')));

const Event = Loadable(lazy(() => import('views/benefit/event/EventList')));
const EventCreate = Loadable(lazy(() => import('views/benefit/event/EventCreate')));
const EventDetail = Loadable(lazy(() => import('views/benefit/event/EventDetail')));
const EventUpdate = Loadable(lazy(() => import('views/benefit/event/EventUpdate')));

const EventPromotionList = Loadable(lazy(() => import('views/benefit/eventPromotion/EventPromotionList')));
const EventPromotionCreate = Loadable(lazy(() => import('views/benefit/eventPromotion/EventPromotionCreate')));
const EventPromotionDetail = Loadable(lazy(() => import('views/benefit/eventPromotion/EventPromotionDetail')));
const EventPromotionUpdate = Loadable(lazy(() => import('views/benefit/eventPromotion/EventPromotionUpdate')));

// 입점사 관리
const VendorList = Loadable(lazy(() => import('views/vendor/VendorList')));
const VendorDetail = Loadable(lazy(() => import('views/vendor/detail/index')));
const VendorCreate = Loadable(lazy(() => import('views/vendor/create/index')));
// const NotVendorList = Loadable(lazy(() => import('views/vendor/notVendor/index')));
// const NotVendorCreate = Loadable(lazy(() => import('views/vendor/notVendor/create')));
// const NotVendorUpdate = Loadable(lazy(() => import('views/vendor/notVendor/update')));
const VendorRecommendProduct = Loadable(lazy(() => import('views/vendor/product/recommend/EditRecommendProduct')));

// 운영자 관리
const AdminList = Loadable(lazy(() => import('views/admin/AdminList/AdminList')));

// 회원 관리 - 회원관리
const UserList = Loadable(lazy(() => import('views/user/UserList')));
const UserDetail = Loadable(lazy(() => import('views/user/UserDetail')));

// 회원 관리 - 탈퇴회원관리
const LeaveUserList = Loadable(lazy(() => import('views/user/LeaveUserList')));

// 문의 관리
const Inquiry = Loadable(lazy(() => import('views/inquiry/index')));
const InquiryDetail = Loadable(lazy(() => import('views/inquiry/InquiryDetail')));

// 통계 관리
// const Notification = Loadable(lazy(() => import('views/statistics/index')));

// 데이터 관리
const Pin = Loadable(lazy(() => import('views/data/pin/index'))); // 위치핀 관리
const ErpItemList = Loadable(lazy(() => import('views/data/erp-item/list'))); // ERP상품 연동목록
const NaraCampaignList = Loadable(lazy(() => import('views/data/erp-campaign/list'))); // 나라포스 캠페인관리

// const MessageTemplate = Loadable(lazy(() => import('views/data/messageTemplate/index')));
// const NaverEpGenerator = Loadable(lazy(() => import('views/data/naver/EpGenerator')));

// 포스트 관리
const MainMagazine = Loadable(lazy(() => import('views/post/MainMagazine/MagazineList')));
const MagazineCreate = Loadable(lazy(() => import('views/post/MainMagazine/MagazineCreate')));
const MagazineDetail = Loadable(lazy(() => import('views/post/MainMagazine/MagazineDetail')));
const MagazineUpdate = Loadable(lazy(() => import('views/post/MainMagazine/MagazineUpdate')));

// const Review = Loadable(lazy(() => import('views/post/review/ReviewList')));
const ReviewList = Loadable(lazy(() => import('views/post/review/ReviewList_v2')));
const ReviewDetail = Loadable(lazy(() => import('views/post/review/ReviewDetail')));
const FAQ = Loadable(lazy(() => import('views/post/faq/index')));
const FAQCreate = Loadable(lazy(() => import('views/post/faq/FAQCreate')));
const FAQUpdate = Loadable(lazy(() => import('views/post/faq/FAQUpdate')));
const Notice = Loadable(lazy(() => import('views/post/notice/index')));
const NoticeCreate = Loadable(lazy(() => import('views/post/notice/NoticeCreate')));
const NoticeUpdate = Loadable(lazy(() => import('views/post/notice/NoticeUpdate')));

// 정책/약관관리
const ServicePolicy = Loadable(lazy(() => import('views/policy/Service')));
const PersonalPolicy = Loadable(lazy(() => import('views/policy/PersonalInformation')));
const TransactionPolicy = Loadable(lazy(() => import('views/policy/Transaction')));
const LocationPolicy = Loadable(lazy(() => import('views/policy/LocationInformation')));
const PersonalAgree = Loadable(lazy(() => import('views/policy/PersonalAgree')));
// const PartnersAgree = Loadable(lazy(() => import('views/policy/PartnersAgree')));
// const PartnersUniqueAgree = Loadable(lazy(() => import('views/policy/PartnersUniqueAgree')));

// GPT 관련
// const GptTalk = Loadable(lazy(() => import('views/gpt/GptTalk')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <PartGuard excepts={[Part.WineDataManager, Part.DataChecker]}>
        <MainLayout />
      </PartGuard>
    </AuthGuard>
  ),
  children: [
    // {
    //   // GPT 대화
    //   path: '/gpt/talk',
    //   element: (
    //     <PartGuard excepts={[Part.Observer]}>
    //       <GptTalk />
    //     </PartGuard>
    //   )
    // },
    {
      // 대시보드
      path: '/dashboard/default',
      element: <DashboardDefault />
    },
    {
      // 정산 관리
      path: '/calculate',
      element: (
        <PartGuard accepts={[Part.Admin, Part.SubAdmin]}>
          <Calculate />
        </PartGuard>
      )
    },
    {
      // 입점샵 정산
      path: '/calculate-vendor',
      element: (
        <PartGuard accepts={[Part.Admin, Part.SubAdmin]}>
          <CalculateVendor />
        </PartGuard>
      )
    },
    {
      // 정산 내역
      path: '/calculate-history',
      element: (
        <PartGuard accepts={[Part.Admin, Part.SubAdmin]}>
          <CalculateHistory />
        </PartGuard>
      )
    },
    {
      // 정산 내역 상세
      path: '/calculate-history/:id',
      element: (
        <PartGuard accepts={[Part.Admin, Part.SubAdmin]}>
          <CalculateHistoryDetail />
        </PartGuard>
      )
    },
    {
      // 배너 관리
      path: '/benefit/banner',
      element: <Banner />
    },
    {
      // 배너 관리 > 배너 추가
      path: '/benefit/banner/create',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <BannerCreate />
        </PartGuard>
      )
    },
    {
      // 배너 관리 > 배너 수정
      path: '/benefit/banner/update/:id',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <BannerUpdate />
        </PartGuard>
      )
    },
    {
      // 이벤트/혜택관리 > 쿠폰 관리
      path: '/benefit/coupon',
      element: <Coupon />
    },
    {
      // 이벤트/혜택관리 > 쿠폰 관리 > 쿠폰 등록
      path: '/benefit/coupon/create',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <CouponCreate />
        </PartGuard>
      )
    },
    {
      // 이벤트/혜택관리 > 쿠폰 관리 > 쿠폰 상세
      path: '/benefit/coupon/:id',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <CouponDetail />
        </PartGuard>
      )
    },
    {
      // 이벤트 관리
      path: '/benefit/event',
      element: <Event />
    },
    {
      // 이벤트 관리 > 이벤트 추가
      path: '/benefit/event/create',
      element: <EventCreate />
    },
    {
      // 이벤트 관리 > 이벤트 추가
      path: '/benefit/event/detail/:id',
      element: <EventDetail />
    },
    {
      // 이벤트 관리 > 이벤트 수정
      path: '/benefit/event/update/:id',
      element: <EventUpdate />
    },
    {
      // 이벤트 관리 > 프로모션 관리
      path: '/benefit/promotion',
      element: <EventPromotionList />
    },
    {
      // 이벤트 관리 > 프로모션 등록
      path: '/benefit/promotion/create',
      element: <EventPromotionCreate />
    },
    {
      // 이벤트 관리 > 프로모션 상세
      path: '/benefit/promotion/:id',
      element: <EventPromotionDetail />
    },
    {
      // 이벤트 관리 > 프로모션 수정
      path: '/benefit/promotion/:id/update',
      element: <EventPromotionUpdate />
    },
    {
      // 이벤트 관리 > 푸쉬메시지 관리
      path: 'benefit/push',
      element: <Push />
    },
    {
      // 이벤트 관리 > 푸쉬메시지 생성
      path: 'benefit/push/create',
      element: <PushCreate />
    },
    {
      // 메인 팝업 관리
      path: '/benefit/main-popup',
      element: <MainPopupList />
    },
    {
      // 메인 팝업 관리 > 팝업 추가
      path: '/benefit/main-popup/create',
      element: <MainPopupCreate />
    },
    {
      // 메인 팝업 관리 > 팝업 수정
      path: '/benefit/main-popup/update/:id',
      element: <MainPopupUpdate />
    },
    {
      // 입점샵 목록
      path: '/vendor/list',
      element: <VendorList />
    },
    {
      // 입점샵 상세
      path: '/vendor/:id',
      element: <VendorDetail />
    },
    {
      // 입점샵 추가
      path: '/vendor/create',
      element: <VendorCreate />
    },
    // {
    //   // 미입점샵 목록
    //   path: '/not-vendor/list',
    //   element: <NotVendorList />
    // },
    // {
    //   // 미입점샵 등록
    //   path: '/not-vendor/create',
    //   element: <NotVendorCreate />
    // },
    // {
    //   // 미입점샵 수정
    //   path: '/not-vendor/:id',
    //   element: <NotVendorUpdate />
    // },
    {
      // 입점샵 추천상품 관리
      path: '/vendor/product/recommend/edit',
      element: <VendorRecommendProduct />
    },
    {
      // 운영자 관리
      path: '/admin/list',
      element: <AdminList />
    },
    {
      // 회원 관리
      path: '/user',
      element: <UserList />
    },
    {
      // 회원 관리상세
      path: '/user/detail/:id',
      element: <UserDetail />
    },
    {
      // 탈퇴 회원 관리
      path: '/user/leave',
      element: <LeaveUserList />
    },
    {
      // 문의관리 > 입점샵 문의관리
      path: '/inquiry/vendor',
      element: <Inquiry />
    },
    {
      // 문의관리 > 입점샵 문의관리 > 상세
      path: '/inquiry/vendor/:id',
      element: <InquiryDetail />
    },
    // {
    //   // 통계관리 > 알림 통계
    //   path: '/notification',
    //   element: <Notification />
    // },
    {
      // 데이터 관리 > 위치 핀 관리
      path: '/data/pin',
      element: <Pin />
    },
    {
      // 데이터 관리 > ERP상품 연동관리
      path: '/data/erp/item/list',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <ErpItemList />
        </PartGuard>
      )
    },
    {
      // 데이터관리 > 나라포스 캠페인관리
      path: '/data/erp/campaign/list',
      element: (
        <PartGuard excepts={[Part.Observer]}>
          <NaraCampaignList />
        </PartGuard>
      )
    },
    // {
    //   // 데이터 관리 > 메세지 템플릿
    //   path: '/data/message-template',
    //   element: <MessageTemplate />
    // },
    // 메인 콘텐츠 관리
    {
      path: '/post/main-magazine',
      element: <MainMagazine />
    },
    {
      // 메인 콘텐츠 > 콘텐츠 추가
      path: '/post/magazine/create',
      element: <MagazineCreate />
    },
    {
      // 메인 콘텐츠 > 콘텐츠 상세
      path: '/post/magazine/detail/:id',
      element: <MagazineDetail />
    },
    {
      // 메인 콘텐츠 > 콘텐츠 수정
      path: '/post/magazine/update/:id',
      element: <MagazineUpdate />
    },
    {
      // 리뷰 관리 > 리뷰 목록
      path: '/post/review',
      element: <ReviewList />
    },
    {
      // 리뷰 관리 > 리뷰 상세
      path: '/post/review/detail/:review_id',
      element: <ReviewDetail />
    },
    {
      // FAQ 관리 > FAQ 리스트
      path: '/post/faq',
      element: <FAQ />
    },
    {
      // FAQ 관리 > FAQ 등록
      path: '/post/faq/create',
      element: <FAQCreate />
    },
    {
      // FAQ 관리 > FAQ 수정
      path: '/post/faq/update/:id',
      element: <FAQUpdate />
    },
    {
      // FAQ 관리 > FAQ 리스트
      path: '/post/notice',
      element: <Notice />
    },
    {
      // FAQ 관리 > FAQ 등록
      path: '/post/notice/create',
      element: <NoticeCreate />
    },
    {
      // FAQ 관리 > FAQ 수정
      path: '/post/notice/update/:id',
      element: <NoticeUpdate />
    },
    {
      // 정책/약관관리 > 서비스 이용약관
      path: '/policy/service',
      element: <ServicePolicy />
    },
    {
      // 정책/약관관리 > 개인정보 처리방침
      path: '/policy/personal_information',
      element: <PersonalPolicy />
    },
    {
      // 정책/약관관리 > 정자금융거래 이용약관
      path: '/policy/transaction',
      element: <TransactionPolicy />
    },
    {
      // 정책/약관관리 > 위치정보 이용약관
      path: '/policy/location_information',
      element: <LocationPolicy />
    },
    {
      // 정책/약관관리 > 일반회원 개인정보 동의서
      path: '/policy/personal_agree',
      element: <PersonalAgree />
    }
    // {
    //   // 정책/약관관리 > 파트너스 개인정보 동의서
    //   path: '/policy/partners-agree',
    //   element: <PartnersAgree />
    // },
    // {
    //   // 정책/약관관리 > 파트너스 고유식별정보 동의서
    //   path: '/policy/partners-unique-agree',
    //   element: <PartnersUniqueAgree />
    // }
  ]
};

export default MainRoutes;
